<template>
  <div class="d-flex w-100 flex-column align-items-center">
    <AppText
      v-if="title"
      :size="titleSize"
      weight="400"
      mb="20px"
      class="font-medium"
    >
      {{ title }}
    </AppText>
    <AppText
      size="16px"
      :line-height="1.3"
      opacity="0.6"
    >
      {{ subtitle }}
    </AppText>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AuthCardWrapper',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    titleSize: {
      type: String,
      default: '33px',
    },
  },
  setup() {
    return {};
  },
});
</script>
