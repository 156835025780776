<template>
  <ResponsiveBlockWrapper class="step1" :is-visible="isVisible">
    <AuthCardWrapper title="Admin Registration" title-size="24px">
      <FInput
        v-model="username"
        class="email"
        disabled
        label="Username"
      >
        <template #append>
          <AppIcon name="lock-grey-thin" size="18px" />
        </template>
      </FInput>
      <div style="margin-bottom: 20px;" />
      <FInput v-model="email" disabled label="Email">
        <template #append>
          <AppIcon name="lock-grey-thin" size="18px" />
        </template>
      </FInput>

      <FButton
        type="black"
        is-full-width
        style="margin-top: 20px;"
        @click="onRegister"
      >
        Register
      </FButton>
    </AuthCardWrapper>
  </ResponsiveBlockWrapper>
</template>

<script>
import { onBeforeMount, ref } from 'vue';

import ResponsiveBlockWrapper from '@/components/Animation/ResponsiveBlockWrapper.vue';

import { useRoute } from '@/router';

import AuthCardWrapper from '../../AuthCardWrapper.vue';
import { openRegistrationLink } from '../api';

export default {
  name: 'RegStep1',
  components: {
    AuthCardWrapper,
    ResponsiveBlockWrapper,
  },

  emits: ['submit', 'error'],
  setup(props, { emit }) {
    const username = ref('');
    const email = ref('');

    const route = useRoute();

    const onRegister = async () => {
      emit('submit');
    };

    const isVisible = ref(false);
    onBeforeMount(async () => {
      const { isSuccess, data } = await openRegistrationLink(route.params.hash);

      if (isSuccess) {
        isVisible.value = true;
        username.value = data.username;
        email.value = data.email;
      } else {
        emit('error');
      }
    });

    return {
      isVisible,

      username,
      email,

      onRegister,
    };
  },
};
</script>

<style lang="scss" scoped>
.step1 {
  :deep(.wrapper-content) {
    width: 100%;
  }

  :deep(.f-input__container > .is-disabled > .el-input__inner) {
    background: var(--color-F2F2F2);
    color: var(--color-black-04);
  }

  .email {
    margin-top: 30px;
  }
}
</style>
