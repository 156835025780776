import { useTempFetch } from '@/api';
import { logout } from '@/api/auth';

export const openRegistrationLink = async (invitationLink) => {
  await logout({ pushToHome: false, useLogoutEndpoint: false });
  const { axiosTempWrapper } = useTempFetch();

  const { data, isSuccess, error } = await axiosTempWrapper({
    type: 'post',
    url: '/admin/open-link',
    params: { invitationLink },
  });

  return { isSuccess, data, error };
};

export const totpGenerate = async (isFresh = false) => {
  const { axiosTempWrapper } = useTempFetch();

  const { data, isSuccess, error } = await axiosTempWrapper({
    type: 'post',
    url: '/admin/totp/generate',
    isFreshResponse: isFresh,
  });

  const otpLink = isSuccess ? `otpauth://totp/Switch (${data?.label})?secret=${data.totpSecret}` : '';

  return { isSuccess, otpLink, error };
};

export const totpEnable = async (code) => {
  const { axiosTempWrapper } = useTempFetch();

  const { data, isSuccess, errorObject } = await axiosTempWrapper({
    type: 'post',
    url: '/admin/totp/enable',
    params: {
      totpCode: code,
    },
  });

  return { isSuccess, data, errorObject };
};

export const generateRecoveryCodes = async () => {
  const { axiosTempWrapper } = useTempFetch();

  const { data, isSuccess, errorObject } = await axiosTempWrapper({
    type: 'get',
    url: '/auth/totp/recovery-keys/generate',
  });

  return { isSuccess, data, errorObject };
};

export const adminCompleteRegistration = async () => {
  const { axiosTempWrapper } = useTempFetch();

  const { isSuccess } = await axiosTempWrapper({
    type: 'post',
    url: '/admin/complete-registration',
  });

  return { isSuccess };
};

export default {
  totpGenerate,
  generateRecoveryCodes,
  totpEnable,
  adminCompleteRegistration,
};
