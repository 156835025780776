<template>
  <div ref="wrapper" class="step-wrapper d-flex flex-column align-items-center">
    <div v-show="isVisible" class="form__wrapper d-flex flex-column align-items-center">
      <div ref="wrapperContent" class="wrapper-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  props: {
    width: {
      type: String,
      default: '500px',
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const wrapper = ref(null);
    const wrapperContent = ref(null);

    const resizeObserver = new ResizeObserver((() => {
      let contentHeight = wrapperContent.value?.offsetHeight;
      if (contentHeight < 250) {
        contentHeight = 250;
      }
      if (contentHeight > 600) {
        contentHeight = 600;
      }

      contentHeight += 120;

      if (wrapper.value && wrapper.value.style) {
        wrapper.value.style.setProperty('--contentHeight', `${contentHeight || '330'}px`);
      }
    }));

    onMounted(() => {
      setTimeout(() => { resizeObserver.observe(wrapperContent.value); }, 0);
    });

    return {
      wrapper,
      wrapperContent,
    };
  },
});
</script>

<style scoped lang="scss">
.form__wrapper{
  position: relative;
  background-color: var(--color-white);
  border: 1px solid var(--color-black-008);
  box-sizing: border-box;
  box-shadow: 0 4px 8px var(--color-black-004);
  border-radius: 20px;
  width: v-bind('width');
  padding: 60px;

  max-height: var(--contentHeight);
  min-height: var(--contentHeight);

  transition: all 0.1s cubic-bezier(0, 1.2, 1, 0.97);
}

.wrapper-content {
  width: auto;
  position: relative;

}
</style>
